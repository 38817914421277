import * as SVGS from "./svgs/svgs";
import css from "./TitleSection.module.scss";
import classNames from "classnames";
import { RightArrow } from "./svgs/svgs";
import React, { useRef, useState } from "react";

export const TitleSection = () => {
  return (
    <div className={css.backgroundContainer}>
      <div className={css.background}>
        <BackgroundElement svg={<SVGS.Ribbing />} className={css.ribbing} />
        <HeroSection />
        <BackgroundElement svg={<SVGS.Cloud1 />} className={css.cloud1} />
        <BackgroundElement svg={<SVGS.Cloud2 />} className={css.cloud2} />
        <BackgroundElement svg={<SVGS.Lake />} className={css.lake} />
        <BackgroundElement svg={<SVGS.Star1 />} className={css.star1} />
        <BackgroundElement svg={<SVGS.Star2 />} className={css.star2} />
        <BackgroundElement svg={<SVGS.Star3 />} className={css.star3} />
        <BackgroundElement svg={<SVGS.Star4 />} className={css.star4} />
        <BackgroundElement svg={<SVGS.Star5 />} className={css.star5} />
        <BackgroundElement svg={<SVGS.Cloud4 />} className={css.cloud4} />
        <BackgroundElement svg={<SVGS.Cloud3 />} className={css.cloud3} />
      </div>
    </div>
  );
};

const BackgroundElement = ({
  svg,
  className,
}: {
  svg: JSX.Element;
  className: string;
}) => {
  return <div className={classNames(css.svgContainer, className)}>{svg}</div>;
};

const HeroSection: React.FC = () => {
  return (
    <div className={css.titleContainer}>
      <h6>April 2025</h6>
      <h1 className={css.titleText}>LA HACKS 2025</h1>
      <EmailInput />
    </div>
  );
};

const EmailInput = () => {
  const [mailChimpResponse, setMailChimpResponse] = useState("");
  const emailInput = useRef<HTMLInputElement>(null);
  const addEmail = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const response = await fetch("/v1/email", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailInput.current?.value,
        }),
      });
      const json = await response.json();
      if (json.success) {
        setMailChimpResponse("We'll be in touch!");
      } else if (json.reason === "Member Exists") {
        setMailChimpResponse("You are already on the list!");
      } else {
        setMailChimpResponse(json.reason || "Invalid email");
      }
    } catch (e) {
      setMailChimpResponse("Invalid email");
    }
  };

  const hasError =
    mailChimpResponse !== "Confirmed" && mailChimpResponse !== "";

  return (
    <div className={css.emailInputWrapper}>
      <div className={css.emailInputContainer}>
        <input
          placeholder="Enter email to stay updated"
          className={css.emailInput}
          ref={emailInput}
        ></input>
        <button onClick={addEmail}>
          <RightArrow />
        </button>
      </div>
      <MailChimpResponse response={mailChimpResponse} />
    </div>
  );
};

const MailChimpResponse: React.FC<{ response: string }> = ({ response }) => {
  if (!response) {
    return null;
  }
  if (
    response === "You are already on the list!" ||
    response === "We'll be in touch!"
  ) {
    return (
      <div className={css.success}>
        <p>{response}</p>
      </div>
    );
  }
  return (
    <div className={css.error}>
      <p> * {response}</p>
    </div>
  );
};
